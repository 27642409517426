import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"

import Tab, { ACTIVE_TAB_CLASSNAME } from "./Tab"
import { Color } from "../../../../constants/V2/color"
import DownloadAppButton from "../../../elements/V2/Buttons/DownloadAppButton"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import GatsbyStoryBlokImage from "../../../elements/V2/GatsbyStoryblokImage"
import { GridSectionTitleProps } from "../../../elements/V2/GridSectionTitles"
import Video from "../../../elements/V2/Video"
import GridSection from "../../Layouts/GridSection"

import { getAccentColor } from "@utils/V2/color"

interface BenefitDriverTab {
  leadText: string
  bodyText: string
  image: string
  imageAlt: string
  video?: string
}

type Props = {
  mode: "light" | "dark"
  tabs: BenefitDriverTab[]
  buttonText: string
  buttonUrl: string
  showDownloadAppButton: boolean
} & GridSectionTitleProps

export default function BenefitDriver({
  mode,
  superscriptText,
  headingText,
  headingBodyText,
  tabs,
  buttonText,
  buttonUrl,
  showDownloadAppButton = false,
  ...props
}: Props) {
  const [activeTab, setActiveTab] = useState(0)
  const activeLineRef = useRef<HTMLSpanElement>(null)
  const tabContaineRef = useRef<HTMLDivElement>(null)

  const backgroundColor = mode === "light" ? Color.White : Color.Charcoal

  useEffect(() => {
    let timeout: number

    /**
     * Calculate and set transform property of active line to match
     * active tab.
     *
     * Using a timeout with duration of tab transition to accurately calculate
     * where the active line position should be.
     * */
    window.setTimeout(() => {
      if (activeLineRef.current && tabContaineRef.current) {
        const activeTab = tabContaineRef.current.querySelector(
          `.${ACTIVE_TAB_CLASSNAME}`
        ) as HTMLDivElement

        activeLineRef.current.style.transform = `translateY(${activeTab?.offsetTop}px)`
      }
    }, 300)

    return () => {
      if (timeout) {
        window.clearTimeout(timeout)
      }
    }
  }, [activeTab])

  return (
    <GridSection
      primaryBackgroundColor={backgroundColor}
      superscriptText={superscriptText}
      headingText={headingText}
      headingBodyText={headingBodyText}
      headingSize="large"
      alignment="left"
      {...props}
    >
      <div className="order-2 relative sm-v2:order-1 col-span-full sm-v2:col-span-2 md-v2:col-span-3 lg-v2:col-span-6">
        <div className="flex">
          <div
            className={classNames(
              "w-px relative flex-shrink-0",
              {
                "bg-progress-line-charcoal": mode === "light",
              },
              {
                "bg-progress-line-white": mode === "dark",
              }
            )}
          >
            <span
              ref={activeLineRef}
              className="block w-[3px] h-32-v2 absolute bg-blue-v2 top-0 left-0 transition-transform ease-in-out duration-300"
            />
          </div>

          <div
            ref={tabContaineRef}
            className="p-xl-v2 flex flex-col lg-v2:gap-y-40-v2 md-v2:gap-y-32-v2 gap-y-20-v2"
          >
            {tabs.map((tab, index) => (
              <Tab
                onClick={() => {
                  setActiveTab(index)
                }}
                key={`tab-${index}`}
                bodyText={tab.bodyText}
                leadText={tab.leadText}
                textColor={getAccentColor(backgroundColor)}
                active={index === activeTab}
              />
            ))}
          </div>
        </div>

        <div className="p-xl-v2">
          {showDownloadAppButton ? (
            <DownloadAppButton
              style="bordered"
              text={buttonText}
              color={Color.Blue}
              overrideTextColor={getAccentColor(backgroundColor)}
            />
          ) : (
            <>
              {buttonText && buttonUrl ? (
                <PillButton
                  text={buttonText}
                  linkUrl={buttonUrl}
                  color={Color.Blue}
                  style="bordered"
                  size="large"
                  overrideTextColor={getAccentColor(backgroundColor)}
                />
              ) : null}
            </>
          )}
        </div>
      </div>
      <div className="order-1 sm-v2:order-2 col-span-full sm-v2:col-span-2 md-v2:col-span-3 lg-v2:col-span-6 grid">
        {tabs.map((tab, index) =>
          tab.video ? (
            <Video
              preventLoop
              coverImage={tab.image}
              aspectRatio="1:1"
              video={tab.video}
              width={600}
              playbackMode="autoplay"
              className={classNames(
                "col-start-1 row-start-1 transition-opacity ease-in-out duration-300 opacity-0",
                { "opacity-100": activeTab === index }
              )}
              ImageAlt={tab.imageAlt}
              rounded
              key={`tab-image-${index}`}
              reset={index !== activeTab}
              pause={index !== activeTab}
            />
          ) : (
            <GatsbyStoryBlokImage
              image={tab.image}
              alt={tab.imageAlt}
              className={classNames(
                "col-start-1 row-start-1 transition-opacity ease-in-out duration-300 opacity-0",
                { "opacity-100": activeTab === index }
              )}
              rounded
              aspectRatio="1:1"
              width={600}
              key={`tab-image-${index}`}
            />
          )
        )}
      </div>
    </GridSection>
  )
}
